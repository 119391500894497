<template>
  <div class="pbf">

     <GrowerTabFollowing
      :inurl="'/users/' + props.id + '/following'"      
      />

  </div>
</template>

<script setup>
 
const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();

var grower_id = ref(route.params.id);

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  id: {
    type: Number
  }
});

// useHead(useNuxtApp().$head.getGrowerFollowing(props.data));

</script>

<style scoped>
 

</style>

